/**
 * Generate a Universally Unique Identifier (UUID)
 * @see https: //stackoverflow.com/a/2117523/38375
 * @returns {string} the generated UUID
 */
export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

let IntlTel_UtilsLoadPromise = null
export function IntlTel_loadUtils(): Promise<boolean> {
  // NOTE(Zara): This loadUtils API is awful. It Should always return a promise.
  // To circumvent this, we are storing the promise in a global variable
  // and returning it when loadUtils returns null (which it does after the first time we call it)
  const promise = (window as any).intlTelInputGlobals.loadUtils(
    'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.16/build/js/utils.js'
  )
  if (promise) {
    IntlTel_UtilsLoadPromise = promise.then((data) => {
      // NOTE(Zara): We should always see this message only once in the console.
      // Indenpendent of how many times we call IntlTel_loadUtils
      console.log('Phone utils loaded!!')
      return data
    })
  }
  return IntlTel_UtilsLoadPromise
}

export function IntlTel_initPhoneInput(inputElement: HTMLInputElement, options: any = {}): Promise<any> {
  // NOTE: We don't need to wait for load utils to complete because internally
  // the intlTelInput library will call 'handleLoadUtils' on all instances created
  // if the caller function needs to await for the input to be fully loaded it can use
  // the returned promise that will be resolved when the input is fully loaded
  IntlTel_loadUtils()

  const iti = (window as any).intlTelInput(inputElement, {
    autoPlaceholder: 'aggressive',
    preferredCountries: ['us', 'ca', 'gb', 'ie', 'ai', 'nz'],
    // TODO: I think the intialCountry logic should be placed in here
    // - First try to get the country from garlic
    // - Second from cfVisitorData
    // - Third from the browser
    // - Fourth falls back to US
    initialCountry: 'us',
    ...options,
  })

  ;(inputElement as any).iti = iti
  return iti.promise
}

export function closeOnClickOutside(element: Element, cb: () => void): void {
  document.addEventListener(
    'click',
    (evt) => {
      if (evt.target !== element && !element.contains(evt.target as Element)) {
        cb()
      }
    },
    { capture: true }
  )
}

export function range(min: number, max: number, step = 1): number[] {
  let arraySize = Math.floor((max - min) / step)
  if (arraySize < 0) arraySize = 0
  return new Array(arraySize).fill(0).map((_, index) => index * step + min)
}

export function removePageScroll(): void {
  document.body.classList.add('remove-page-scroll')
}

export function addPageScroll(): void {
  document.body.classList.remove('remove-page-scroll')
}
